import apiClient from '@/services/axios'

const END_POINT = '/xml/popfeed'

/**
 * Get remote rtb zones
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */

export async function getPopFeedById(id) {
  return apiClient.get(END_POINT + '/get?Id=' + id).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getPopFeeds(id = null) {
  const payload = id != null ? { Id: id } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getPopFeedsForPublisher(publisherId) {
  return apiClient.get(END_POINT + '/get', {
    params: { PublisherId: publisherId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getPopFeedsShort() {
  return apiClient.get(END_POINT + '/get/short', {
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getPopFeedsForUserAndAdvertiser(accManagerId, advertiserId) {
  let paramAccManagerId = accManagerId
  let paramAdvertiserId = advertiserId
  if (!paramAccManagerId) {
    paramAccManagerId = '0'
  } else if (!paramAdvertiserId) {
    paramAdvertiserId = '0'
  }
  return apiClient.get(END_POINT + '/get', {
    params: { AccManagerId: accManagerId, AdvertiserId: advertiserId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new Ad
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createPopFeed(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    PublisherId: {
      type: 'int',
      required: true,
      map: 'publisher',
    },
    Name: {
      type: 'string',
      required: true,
    },
    Bidfloor: {
      map: 'bidfloor',
      type: 'float',
      required: false,
    },
    PublisherRevShare: {
      type: 'int',
      required: false,
      map: 'publisherRevShare',
    },
    Secure: {
      type: 'int',
      required: false,
      map: 'secure',
    },
    AuctionType: {
      type: 'int',
      required: false,
      map: 'auctionType',
    },
    RequestsDailyLimit: {
      type: 'int',
      required: false,
      map: 'requestsDailyLimit',
    },
    BidsDailyLimit: {
      type: 'int',
      required: false,
      map: 'bidsDailyLimit',
    },
    ImpressionsDailyLimit: {
      type: 'int',
      required: false,
      map: 'impressionsDailyLimit',
    },
    SpentDailyLimit: {
      type: 'float',
      required: false,
      map: 'spentDailyLimit',
    },
    Tmax: {
      type: 'int',
      required: false,
      map: 'tmax',
    },
    SubidListMode: {
      type: 'int',
      required: false,
    },
    SubidList: {
      type: 'arrayOfString',
      required: false,
    },
    KeywordBlacklistsType: {
      type: 'int',
      required: false,
    },
    KeywordBlacklists: {
      type: 'arrayOfInt',
      required: 'false',
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.SubidList !== 'undefined' && preparedData.SubidList.length > 0) {
    preparedData.SubidList = preparedData.SubidList.join('\n')
  }
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/add', { Popfeed: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update Remote RTB Zone
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updatePopFeed(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
      map: 'id',
    },
    Name: {
      type: 'string',
      required: true,
    },
    Bidfloor: {
      map: 'bidfloor',
      type: 'float',
      required: false,
    },
    PublisherRevShare: {
      type: 'int',
      required: false,
      map: 'publisherRevShare',
    },
    Secure: {
      type: 'int',
      required: false,
      map: 'secure',
    },
    AuctionType: {
      type: 'int',
      required: false,
      map: 'auctionType',
    },
    RequestsDailyLimit: {
      type: 'int',
      required: false,
      map: 'requestsDailyLimit',
    },
    BidsDailyLimit: {
      type: 'int',
      required: false,
      map: 'bidsDailyLimit',
    },
    ImpressionsDailyLimit: {
      type: 'int',
      required: false,
      map: 'impressionsDailyLimit',
    },
    SpentDailyLimit: {
      type: 'float',
      required: false,
      map: 'spentDailyLimit',
    },
    Tmax: {
      type: 'int',
      required: false,
      map: 'tmax',
    },
    Remotertbzones: {
      map: 'remotertbzones',
      type: 'arr',
      required: false,
    },
    Dsppopfeeds: {
      map: 'dsppopfeeds',
      type: 'arr',
      required: false,
    },
    SubidListMode: {
      type: 'int',
      required: false,
    },
    SubidList: {
      type: 'arrayOfString',
      required: false,
    },
    KeywordBlacklistsType: {
      type: 'int',
      required: false,
    },
    KeywordBlacklists: {
      type: 'arrayOfInt',
      required: 'false',
    },
  }
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.SubidList !== 'undefined' && preparedData.SubidList.length > 0) {
    preparedData.SubidList = preparedData.SubidList.join('\n')
  }
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }
  return apiClient.post(END_POINT + '/update', { Popfeed: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change Ad
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changePopFeedStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Popfeed: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Duplicate existing Add
 *
 * @param {int} id - add id
 * @returns {Promise} - Request promise
 */
export async function clonePopFeed(id) {
  // todo add type check
  return apiClient.post(END_POINT + '/duplicate', {
    Popfeed: { Id: id },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
