import apiClient from '@/services/axios'

const END_POINT = '/advertiser'

/**
 * Get user's team info
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getAdvertisersShort(id = null) {
  const Id = id != null ? { Id: id } : null

  return apiClient.get(END_POINT + '/get/short', { params: Id }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getAdvertisers(id = null) {
  const Id = id != null ? { Id: id } : null

  return apiClient.get(END_POINT + '/get', { params: Id }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getAdvertisersForUser(accManagerId) {
  return apiClient.get(END_POINT + '/get', {
    params: { AccManagerId: accManagerId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new advertiser
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createAdvertiser(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Mail: {
      type: 'string',
      required: true,
      map: 'email',
    },
    Name: {
      type: 'string',
      required: true,
    },
    Description: {
      type: 'string',
      required: false,
    },
    Website: {
      type: 'string',
      required: false,
    },
    Country: {
      type: 'string',
      required: true,
    },
    City: {
      type: 'string',
      required: false,
    },
    ZipCode: {
      type: 'string',
      required: false,
    },
    Address: {
      type: 'string',
      required: false,
    },
    Phone: {
      type: 'string',
      required: false,
    },
    Skype: {
      type: 'string',
      required: false,
    },
    Username: {
      type: 'string',
      required: true,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/add', { Advertiser: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update advertiser data
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateAdvertiser(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    Mail: {
      type: 'string',
      required: false,
      map: 'email',
    },
    Name: {
      type: 'string',
      required: false,
    },
    Description: {
      type: 'string',
      required: false,
    },
    Website: {
      type: 'string',
      required: false,
    },
    Country: {
      type: 'string',
      required: false,
    },
    City: {
      type: 'string',
      required: false,
    },
    ZipCode: {
      type: 'string',
      required: false,
    },
    Address: {
      type: 'string',
      required: false,
    },
    Phone: {
      type: 'string',
      required: false,
    },
    Skype: {
      type: 'string',
      required: false,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/update', { Advertiser: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change advertiser status
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeAdvertiserStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Advertiser: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
