import Vue from 'vue'
import Vuex from 'vuex'
import { getRemoteRTBZones, createRemoteRTBZone, updateRemoteRTBZone, changeRemoteRTBZoneStatus, getRemoteRTBZonesForAdvertiser, getRemoteRTBZonesForUserAndAdvertiser } from '@/api/display/remotertbzone'
import { getRemoteRTBZonesShort } from '../../../api/display/remotertbzone'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_ZONE(state, payload) {
      const remotertbzone = state.list.find(item => item.Id === parseInt(payload.Id))
      Object.assign(remotertbzone, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_ZONES_FOR_ADVERTISER({ commit, dispatch, rootState }, payload) {
      getRemoteRTBZonesForAdvertiser(payload.AdvertiserId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ZONES_FOR_USER_ADV({ commit, dispatch, rootState }, payload) {
      getRemoteRTBZonesForUserAndAdvertiser(payload.AccManagerId, payload.AdvertiserId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ZONES({ commit, dispatch, rootState }) {
      getRemoteRTBZones().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ZONES_SHORT({ commit, dispatch, rootState }) {
      getRemoteRTBZonesShort().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_ZONE({ commit, dispatch, rootState }, { payload }) {
      return new Promise((resolve, reject) => {
        createRemoteRTBZone(payload).then(response => {
          if (response) {
            if (typeof response.type !== 'undefined' && response.type === 'error') {
              Vue.prototype.$notification.error({
                message: 'Invalid data',
                description: response.message,
              })
              reject(response.message)
            } else if (response.Status === 'Ok') {
              Vue.prototype.$notification.success({
                message: 'Remote RTB Zone Created',
                description: 'Remote RTB Zone has been successfully created.',
              })
              resolve(response)
            }
          }
          dispatch('LOAD_ZONES')
        }).catch(error => {
          reject(error)
        })
      })
    },
    UPDATE_ZONE({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateRemoteRTBZone({ id, ...payload }).then(response => {
        if (response) {
          // commit('SET_ZONE', response.Remotertbzone)
          Vue.prototype.$notification.success({
            message: 'Remote RTB Zone Updated',
            description: 'Remote RTB Zone has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeRemoteRTBZoneStatus(payload).then(response => {
        if (response) {
          commit('SET_ZONE', response.Remotertbzone)
          Vue.prototype.$notification.success({
            message: (response.Remotertbzone.Status === 2 ? 'Remote RTB Zone Deleted' : 'Status Changed'),
            description: (response.Remotertbzone.Status === 2 ? 'Remote RTB Zone successfully deleted.' : 'Remote RTB Zone status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getRemoteRTBZoneData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getRemoteRTBZones: (state) => {
      return state.list
    },
    getActiveRemoteRTBZones: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
