import Vue from 'vue'
import Vuex from 'vuex'
import { getTeam, updateTeam } from '@/api/team'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    id: null,
    name: null,
    website: null,
    country: null,
    city: null,
    zipCode: null,
    address: null,
    phone: null,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_TEAM_DATA({ commit, dispatch, rootState }) {
      getTeam().then(response => {
        commit('SET_STATE', {
          id: response.Id,
          name: response.Name,
          website: response.Website,
          country: response.Country,
          city: response.City,
          zipCode: response.ZipCode,
          address: response.Address,
          phone: response.Phone,
          nonEmptyReferer: response.NonEmptyReferer,
          refererMatch: response.RefererMatch,
          ipMatch: response.IpMatch,
          uaMatch: response.UaMatch,
        })
      })
    },
    UPDATE_TEAM({ commit, dispatch, rootState }, { payload }) {
      updateTeam(payload).then(response => {
        if (response.Status === 'Ok') {
          Vue.prototype.$notification.success({
            message: 'Successfully Changed',
            description: 'You have successfully changed team details.',
          })
          const team = response.Team
          commit('SET_STATE', {
            id: team.Id,
            name: team.Name,
            website: team.Website,
            country: team.Country,
            city: team.City,
            zipCode: team.ZipCode,
            address: team.Address,
            phone: team.Phone,
          })
        } else {
          Vue.prototype.$notification.error({
            message: 'Changes Failed',
            description: response.Error,
          })
        }
      })
    },
  },
  // getters: {
  //   team: state => state,
  // },
}
