<template>
  <div id="app">
    <!-- <router-link class="m-5" to="/xml"><span @click="$store.state.settings.platform = 1">XML MODULE</span></router-link>
    <router-link class="m-5" to="/"><span @click="$store.state.settings.platform = 0">DISPLAY</span></router-link> -->
    <!-- <router-link  to="/display">SLASH</router-link> -->
    <!-- <button @click="$store.state.settings.platform = 1">PLATFORM /</button>
    <button @click="$store.state.settings.platform = 0">PLATFORM XML</button> -->
    <localization></localization>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from 'store'
import Localization from '@/localization'
import perPageData from '@/views/shared-data/perPageData.json'

export default {
  name: 'app',
  components: { Localization },
  computed: {
    ...mapState(['settings']),
    ...mapState('auth', ['authorized']),
    nextRoute() {
      return this.$route.query.redirect || '/display'
    },
    currentRoute() {
      return this.$route.path
    },
  },
  created() {
    window.addEventListener('storage', (e) => {
      if (e.key === 'accessToken') {
        // console.log(store.get('accessToken'))
        if (store.get('accessToken') === undefined && this.$route.name !== 'ortb-dashboard') {
          window.location = '/#/auth/login'
        }
      }
      // console.log(e, this.$route, 'event')
    })
    const data = perPageData
    if (!window.localStorage.getItem('per-page-data')) {
      window.localStorage.setItem('per-page-data', JSON.stringify(data))
    }
  },
  mounted() {
    // console.log(window.localStorage, 'on mount')
    // this.$store.dispatch('auth/LOAD_CURRENT_ACCOUNT')
    this.$store.commit('SET_PRIMARY_COLOR', { color: this.settings.primaryColor })
    this.$store.commit('SET_THEME', { theme: this.settings.theme })
    window.onpopstate = event => {
      // if (window.localStorage.accessToken !== null) {
      //   this.$router.replace({ name: 'dashboard' }).catch(() => {})
      // }
    }
  },
  beforeDestroy() {
    window.removeEventListener('storage', null)
  },
  watch: {
    '$store.state.settings.theme'(theme) {
      this.$store.commit('SET_THEME', { theme })
    },
    authorized(authorized) {
      if (authorized && this.currentRoute === '/auth/login') {
        this.$router.replace(this.nextRoute)
      }
    },
    'window.localStorage.accessToken'() {
      if (!window.localStorage.accessToken) {
        this.$store.dispatch('auth/LOGOUT')
      }
    },
    '$route'(to, from) {
      if ((!window.localStorage.accessToken && !this.authorized && this.$route.path !== '/auth/login' && this.$route.path !== '/auth/register' && from !== '/auth/login') || (this.$route.path === '/' && !this.authorized)) {
        window.localStorage.clear()
        this.$router.replace({ name: 'auth-login' }).catch(() => { })
      } else if (this.authorized && this.currentRoute === '/#/auth/login') {
        this.$router.replace(this.nextRoute).catch(() => { })
      } else {
        const query = Object.assign({}, to.query)
        this.$store.commit('SETUP_URL_SETTINGS', query)
      }
    },
  },
}
</script>
<style>
@media only screen and (max-width: 768px) {
  .items-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1024px) {
  .ant-calendar-range-picker-input {
    font-size: 75%;
  }
}

.ant-input-group-addon>i:only-child:after {
  cursor: pointer;
}
table td {
  position: relative;
}
.background-indicator::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.background-indicator-red::before {
  background-color: #ff50509a;
}
.background-indicator-orange::before {
  background-color: #ff8a3c5a;
}
.background-indicator-yellow::before {
  background-color: #fff13068;
}
.ant-select-dropdown-menu-item-selected.zones {
  color: #595c97 !important;
  font-weight: 600 !important;
  display: none !important;
}
.table-cursor-pointer {
  cursor: pointer;
  transition: color 0.2s ease-in; /* Transition effect for color change */
}

.table-cursor-pointer:hover {
  color: #4b7cf3;
}
</style>
