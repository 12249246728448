import apiClient from '@/services/axios'

const END_POINT = '/xml/dsppopfeed'

/**
 * Get remote rtb zones
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */

export async function getDspPopFeedById(id) {
  return apiClient.get(END_POINT + '/get?Id=' + id).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getDspPopFeeds(id = null) {
  const payload = id != null ? { Id: id } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getDspPopFeedsForAdvertiser(advertiserId) {
  return apiClient.get(END_POINT + '/get', {
    params: { AdvertiserId: advertiserId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getDspPopFeedsShort() {
  return apiClient.get(END_POINT + '/get/short', {
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getDspPopFeedsForUserAndAdvertiser(accManagerId, advertiserId) {
  let paramAccManagerId = accManagerId
  let paramAdvertiserId = advertiserId
  if (!paramAccManagerId) {
    paramAccManagerId = '0'
  } else if (!paramAdvertiserId) {
    paramAdvertiserId = '0'
  }
  return apiClient.get(END_POINT + '/get', {
    params: { AccManagerId: accManagerId, AdvertiserId: advertiserId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new Ad
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createDspPopFeed(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    AdvertiserId: {
      type: 'int',
      required: true,
      map: 'advertiser',
    },
    Name: {
      type: 'string',
      required: true,
    },
    ImpressionsLimitPerIp: {
      type: 'int',
      required: false,
      map: 'impressionsLimitPerIp',
    },
    RequestsDailyLimit: {
      type: 'int',
      required: false,
      map: 'requestsDailyLimit',
    },
    BidsDailyLimit: {
      type: 'int',
      required: false,
      map: 'bidsDailyLimit',
    },
    ImpressionsDailyLimit: {
      type: 'int',
      required: false,
      map: 'impressionsDailyLimit',
    },
    RevenueDailyLimit: {
      type: 'float',
      required: false,
      map: 'revenueDailyLimit',
    },
    FeedUrl: {
      type: 'string',
      required: false,
      map: 'feedUrl',
    },
    Description: {
      map: 'description',
      type: 'string',
      required: false,
    },
    MaxQps: {
      map: 'maxQps',
      type: 'int',
      required: false,
    },
    MaxBidfloor: {
      map: 'maxBidfloor',
      type: 'float',
      required: false,
    },
    MinTmax: {
      type: 'int',
      required: false,
      map: 'minTmax',
    },
    MaxIvt: {
      type: 'int',
      required: false,
      map: 'maxIvt',
    },
    MaxSivt: {
      type: 'int',
      required: false,
      map: 'maxSivt',
    },
    DomainsBrandsListMode: {
      type: 'int',
      required: false,
      map: 'domainsBrandsListMode',
    },
    DomainsBrandsList: {
      type: 'intArray',
      required: false,
      map: 'domainsBrandsList',
    },
    PubIdListMode: {
      type: 'int',
      required: false,
      map: 'pubIdListMode',
    },
    PubIdList: {
      type: 'string',
      required: false,
    },
    IpBlacklists: {
      map: 'ipBlacklists',
      type: 'intArray',
      required: false,
    },
    IabCategories: {
      map: 'iabCategories',
      type: 'arr',
      required: false,
    },
    Countries: {
      type: 'objectArray',
      required: false,
    },
    Os: {
      type: 'objectArray',
      required: false,
    },
    Browsers: {
      type: 'objectArray',
      required: false,
    },
    DeviceTypes: {
      type: 'intArray',
      required: false,
    },
    NonEmptyReferer: {
      type: 'int',
      required: false,
    },
    RefererMatch: {
      type: 'int',
      required: false,
    },
    IpMatch: {
      type: 'int',
      required: false,
    },
    UaMatch: {
      type: 'int',
      required: false,
    },
    AutoOptimize: {
      type: 'int',
      required: false,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/add', { Dsppopfeed: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update Remote RTB Zone
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateDspPopFeed(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
      map: 'id',
    },
    Name: {
      type: 'string',
      required: true,
    },
    ImpressionsLimitPerIp: {
      type: 'int',
      required: false,
      map: 'impressionsLimitPerIp',
    },
    RequestsDailyLimit: {
      type: 'int',
      required: false,
      map: 'requestsDailyLimit',
    },
    BidsDailyLimit: {
      type: 'int',
      required: false,
      map: 'bidsDailyLimit',
    },
    ImpressionsDailyLimit: {
      type: 'int',
      required: false,
      map: 'impressionsDailyLimit',
    },
    RevenueDailyLimit: {
      type: 'float',
      required: false,
      map: 'revenueDailyLimit',
    },
    FeedUrl: {
      type: 'string',
      required: false,
      map: 'feedUrl',
    },
    Description: {
      map: 'description',
      type: 'string',
      required: false,
    },
    MaxQps: {
      map: 'maxQps',
      type: 'int',
      required: false,
    },
    MaxBidfloor: {
      map: 'maxBidfloor',
      type: 'float',
      required: false,
    },
    MinTmax: {
      type: 'int',
      required: false,
      map: 'minTmax',
    },
    MaxIvt: {
      type: 'int',
      required: false,
      map: 'maxIvt',
    },
    MaxSivt: {
      type: 'int',
      required: false,
      map: 'maxSivt',
    },
    DomainsBrandsListMode: {
      type: 'int',
      required: false,
      map: 'domainsBrandsListMode',
    },
    DomainsBrandsList: {
      type: 'intArray',
      required: false,
      map: 'domainsBrandsList',
    },
    PubIdListMode: {
      type: 'int',
      required: false,
      map: 'pubIdListMode',
    },
    PubIdList: {
      type: 'string',
      required: false,
    },
    IpBlacklists: {
      map: 'ipBlacklists',
      type: 'intArray',
      required: false,
    },
    IabCategories: {
      map: 'iabCategories',
      type: 'arr',
      required: false,
    },
    Countries: {
      type: 'objectArray',
      required: false,
    },
    Os: {
      type: 'objectArray',
      required: false,
    },
    Browsers: {
      type: 'objectArray',
      required: false,
    },
    Rtbzones: {
      type: 'intArray',
      required: false,
    },
    Popfeeds: {
      map: 'popfeeds',
      type: 'intArray',
      required: false,
    },
    DeviceTypes: {
      type: 'intArray',
      required: false,
    },
    NonEmptyReferer: {
      type: 'int',
      required: false,
    },
    RefererMatch: {
      type: 'int',
      required: false,
    },
    IpMatch: {
      type: 'int',
      required: false,
    },
    UaMatch: {
      type: 'int',
      required: false,
    },
    AutoOptimize: {
      type: 'int',
      required: false,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }
  return apiClient.post(END_POINT + '/update', { Dsppopfeed: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change Ad
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeDspPopFeedStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Dsppopfeed: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Duplicate existing Add
 *
 * @param {int} id - add id
 * @returns {Promise} - Request promise
 */
export async function cloneDspPopFeed(id) {
  // todo add type check
  return apiClient.post(END_POINT + '/duplicate', {
    Dsppopfeed: { Id: id },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
