import Vue from 'vue'
import Vuex from 'vuex'
import { getRTBZones, createRTBZone, updateRTBZone, changeRTBZoneStatus, getRTBZonesForPublisher, getRTBZonesForUser, getRTBZonesForUserAndPublisher } from '@/api/display/rtbzone'
import { getRTBZonesShort } from '../../../api/display/rtbzone'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_RTBZONE(state, payload) {
      const RTBZone = state.list.find(item => item.Id === parseInt(payload.Id))
      Object.assign(RTBZone, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_RTBZONES_FOR_PUBLISHER({ commit, dispatch, rootState }, payload) {
      getRTBZonesForPublisher(payload.PublisherId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_RTBZONES_FOR_USER({ commit, dispatch, rootState }, payload) {
      getRTBZonesForUser(payload.AccManagerId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    // combine user and publisher filters
    LOAD_RTBZONES_FOR_USER_PUB({ commit, dispatch, rootState }, payload) {
      getRTBZonesForUserAndPublisher(payload.AccManagerId, payload.PublisherId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_RTBZONE({ commit, dispatch, rootState }, id) {
      getRTBZones(id).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_RTBZONES({ commit, dispatch, rootState }) {
      getRTBZones().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_RTBZONES_SHORT({ commit, dispatch, rootState }) {
      getRTBZonesShort().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_RTBZONE({ commit, dispatch, rootState }, { payload }) {
      return new Promise((resolve, reject) => {
        createRTBZone(payload).then(response => {
          if (response) {
            if (typeof response.type !== 'undefined' && response.type === 'error') {
              Vue.prototype.$notification.error({
                message: 'Invalid data',
                description: response.message,
              })
              reject(response.message)
            } else if (response.Status === 'Ok') {
              Vue.prototype.$notification.success({
                message: 'RTB Zone Created',
                description: 'RTB Zone has been successfully created.',
              })
              resolve(response)
            }
          }
          dispatch('LOAD_RTBZONES')
        }).catch(error => {
          reject(error)
        })
      })
    },
    UPDATE_RTBZONE({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateRTBZone({ id, ...payload }).then(response => {
        if (response) {
          // commit('SET_RTBZONE', response.Rtbzone)
          Vue.prototype.$notification.success({
            message: 'RTB Zone Updated',
            description: 'RTB Zone has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeRTBZoneStatus(payload).then(response => {
        if (response) {
          commit('SET_RTBZONE', response.Rtbzone)
          Vue.prototype.$notification.success({
            message: (response.Rtbzone.Status === 2 ? 'RTB Zone Deleted' : 'Status Changed'),
            description: (response.Rtbzone.Status === 2 ? 'RTB Zone successfully deleted.' : 'RTB Zone status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getRTBZoneData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getRTBZones: (state) => {
      return state.list
    },
    getActiveRTBZones: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
