export const getMenuData = [
  {
    title: 'Dashboard',
    key: 'displayDashboard',
    class: 'material-icons-outlined',
    icon: 'dashboard',
    url: 'dashboard',
  },
  {
    title: 'Reports',
    key: 'reports',
    class: 'material-icons-outlined',
    icon: 'bar_chart',
    children: [
      {
        title: 'By Zone',
        key: 'reportsSearchfeed',
        url: 'reports-zone',
        class: 'material-icons-outlined',
      },
      {
        title: 'By Ad',
        key: 'reportsAd',
        url: 'reports-ad',
        class: 'material-icons-outlined',
      },
      // {
      //   title: 'By Campaign',
      //   key: 'reportsCampaign',
      //   url: 'display-reports-campaign',
      //   class: 'material-icons-outlined',
      // },
      {
        title: 'Video',
        key: 'reportsVideo',
        url: 'reports-video',
        class: 'material-icons-outlined',
      },
      // {
      //   title: 'Apps By Zone',
      //   key: 'reportsAppsByZone',
      //   url: 'reports-apps-zone',
      //   class: 'material-icons-outlined',
      // },
      // {
      //   title: 'Apps By Ad',
      //   key: 'reportsAppsByAd',
      //   url: 'reports-apps-ad',
      //   class: 'material-icons-outlined',
      // },
      // {
      //   title: 'Sites By Zone',
      //   key: 'reportsSitesByZone',
      //   url: 'reports-sites-zone',
      //   class: 'material-icons-outlined',
      // },
      // {
      //   title: 'Sites By Ad',
      //   key: 'reportsSitesByAd',
      //   url: 'reports-sites-ad',
      //   class: 'material-icons-outlined',
      // },
      // {
      //   title: 'Sizes By Zone',
      //   key: 'reportsSizesByZone',
      //   url: 'reports-sizes-zone',
      //   class: 'material-icons-outlined',
      // },
      // {
      //   title: 'Sizes By Ad',
      //   key: 'reportsSizesByAd',
      //   url: 'reports-sizes-ad',
      //   class: 'material-icons-outlined',
      // },
      // {
      //   title: 'Pub IDs By Zone',
      //   key: 'reportsPubByZone',
      //   url: 'reports-pub-zone',
      //   class: 'material-icons-outlined',
      // },
      // {
      //   title: 'Pub IDs By Ad',
      //   key: 'reportsPubByAd',
      //   url: 'reports-pub-ad',
      //   class: 'material-icons-outlined',
      // },
      {
        title: 'Seats/CRIDs',
        key: 'reportsSeatsCrids',
        url: 'reports-seatscrids',
        class: 'material-icons-outlined',
      },
      {
        title: 'Errors',
        key: 'reportsError',
        url: 'reports-error',
        class: 'material-icons-outlined',
      },
      {
        title: 'SChain Errors',
        key: 'reportsSchain',
        url: 'reports-schain',
        class: 'material-icons-outlined',
      },
      {
        title: 'IVT',
        key: 'reportsIvt',
        url: 'reports-ivt',
        class: 'material-icons-outlined',
      },
      {
        title: 'Geoedge Alerts',
        key: 'reportsGeoedge',
        url: 'reports-geoedge',
        class: 'material-icons-outlined',
      },
    ],
  },
  {
    title: 'Supply',
    key: 'publishers',
    class: 'material-icons-outlined',
    icon: 'web',
    // roles: ['admin'], // set user roles with access to this route
    // count: 4,
    children: [
      {
        title: 'Publishers',
        tooltip: 'Create Publisher',
        key: 'publishersItem',
        hasButton: true,
        url: 'publishers',
        addUrl: 'publisher-create',
      },
      // {
      //   title: 'Create Publisher',
      //   key: 'createPublisher',
      //   url: '/display/publisher/create',
      // },
      {
        title: 'Banner Zones',
        tooltip: 'Create Banner Zone',
        key: 'bannerzones',
        hasButton: true,
        url: 'bannerzones',
        addUrl: 'bannerzone-create',
      },
      // {
      //   title: 'Create Banner Zone',
      //   key: 'createBannerzone',
      //   url: '/display/bannerzone/create',
      // },
      {
        title: 'Video Zones',
        tooltip: 'Create Video Zone',
        key: 'videozones',
        hasButton: true,
        url: 'videozones',
        addUrl: 'videozone-create',
      },
      // {
      //   title: 'Create Video Zone',
      //   key: 'createVideozone',
      //   url: '/display/videozone/create',
      // },
      {
        title: 'RTB Zones',
        tooltip: 'Create RTB Zone',
        key: 'rtbzones',
        hasButton: true,
        url: 'rtbzones',
        addUrl: 'rtbzone-create',
      },
      {
        title: 'XML Pop Feeds',
        tooltip: 'Create XML Pop Feed',
        key: 'popfeeds',
        hasButton: true,
        url: 'popfeeds',
        addUrl: 'popfeed-create',
      },
      // {
      //   title: 'Create RTB Zone',
      //   key: 'createRTBZone',
      //   url: '/display/rtbzone/create',
      // },
    ],
  },
  {
    title: 'Demand',
    key: 'campaigns',
    class: 'material-icons-outlined',
    icon: 'featured_video',
    // roles: ['admin'], // set user roles with access to this route
    // count: 4,
    children: [
      {
        title: 'Advertisers',
        tooltip: 'Create Advertiser',
        key: 'advertisers',
        hasButton: true,
        url: 'advertisers',
        addUrl: 'advertiser-create',
      },
      // {
      //   title: 'Create Advertiser',
      //   key: 'createAdvertiser',
      //   url: '/display/advertiser/create',
      // },
      {
        title: 'Campaigns',
        tooltip: 'Create Campaign',
        key: 'campaigns',
        hasButton: true,
        url: 'campaigns',
        addUrl: 'campaign-create',
      },
      // {
      //   title: 'Create Campaign',
      //   key: 'createCampaign',
      //   url: '/display/campaign/create',
      // },
      // {
      //   title: 'Ads',
      //   key: 'ads',
      //   url: '/display/ads',
      // },
      // {
      //   title: 'Create Ad',
      //   key: 'createAd',
      //   url: '/display/ad/create',
      // },
      {
        title: 'Banner Ads',
        tooltip: 'Create Banner Ad',
        key: 'bannerads',
        hasButton: true,
        url: 'bannerads',
        addUrl: 'bannerad-create',
      },
      // {
      //   title: 'Create Banner Ad',
      //   key: 'createBannerAd',
      //   url: '/display/bannerad/create',
      // },
      {
        title: 'Video Ads',
        tooltip: 'Create Video Ad',
        key: 'videoads',
        hasButton: true,
        url: 'videoads',
        addUrl: 'videoad-create',
      },
      {
        title: 'Native Ads',
        tooltip: 'Create Native Ad',
        key: 'nativeads',
        hasButton: true,
        url: 'nativeads',
        addUrl: 'nativead-create',
      },
      // {
      //   title: 'Create Video Ad',
      //   key: 'createVideoAd',
      //   url: '/display/videoad/create',
      // },
      {
        title: 'Remote RTB Zones',
        tooltip: 'Create Remote RTB Zone',
        key: 'remotertbzones',
        hasButton: true,
        url: 'remotertbzones',
        addUrl: 'remotertbzone-create',
      },
      {
        title: 'Remote Video Zones',
        tooltip: 'Create Remote Video Zone',
        key: 'remotevideozones',
        hasButton: true,
        url: 'remotevideozones',
        addUrl: 'remotevideozone-create',
      },
      {
        title: 'XML DSP Pop Feeds',
        tooltip: 'Create XML DSP Pop Feed',
        key: 'dsppopfeeds',
        hasButton: true,
        url: 'dsppopfeeds',
        addUrl: 'dsppopfeed-create',
      },
    ],
  },
  {
    title: 'Library',
    key: 'library',
    class: 'material-icons-outlined',
    icon: 'folder',
    // roles: ['admin'], // set user roles with access to this route
    // count: 4,
    children: [
      {
        title: 'Image Library',
        key: 'imageLibrary',
        class: 'material-icons-outlined',
        // icon: 'photo_library',
        url: 'library-images',
      },
      {
        title: 'Video Library',
        key: 'videoLibrary',
        class: 'material-icons-outlined',
        // icon: 'video_library',
        url: 'library-videos',
      },
      {
        title: 'Sites Library',
        key: 'sites',
        class: 'material-icons-outlined',
        // icon: 'language',
        url: 'library-sites',
      },
      {
        title: 'Apps Library',
        key: 'apps',
        class: 'material-icons-outlined',
        // icon: 'smartphone',
        url: 'library-apps',
      },
      {
        title: 'CRIDs Library',
        key: 'crids',
        class: 'material-icons-outlined',
        // icon: 'smartphone',
        url: 'library-crids',
      },
    ],
  },
  {
    title: 'Lists',
    key: 'lists',
    class: 'material-icons-outlined',
    icon: 'list_alt',
    // roles: ['admin'], // set user roles with access to this route
    // count: 4,
    children: [
      {
        title: 'IP Lists',
        key: 'iplists',
        url: 'iplists',
      },
      {
        title: 'App Lists',
        key: 'applists',
        url: 'applists',
      },
      {
        title: 'Domain Lists',
        key: 'domainlists',
        url: 'domainlists',
      },
      {
        title: 'Keyword Lists',
        key: 'keyword-lists',
        // vrati xml
        url: 'kwlist',
      },
    ],
  },
]
