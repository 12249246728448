import apiClient from '@/services/axios'

const END_POINT = '/xml/ad'

/**
 * Get ads
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getAds(id = null) {
  const payload = id != null ? { params: { Id: parseInt(id) } } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getAdsForCampaign(campaignId) {
  return apiClient.get(END_POINT + '/get', {
    params: { CampaignId: campaignId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new Ad
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createAd(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    CampaignId: {
      type: 'int',
      required: true,
      map: 'campaign',
    },
    Name: {
      type: 'string',
      required: true,
    },
    DefaultCPC: {
      type: 'float',
      required: false,
    },
    ClicksDailyLimit: {
      type: 'int',
      required: false,
    },
    Title: {
      type: 'string',
      required: false,
    },
    Description: {
      type: 'string',
      required: false,
    },
    Description2: {
      type: 'string',
      required: false,
    },
    AdDomainBrand: {
      type: 'string',
      required: false,
    },
    DestinationUrl: {
      type: 'string',
      required: true,
    },
    RemoteChannel: {
      type: 'string',
      required: false,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/add', { Ad: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update Ad
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateAd(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    Name: {
      type: 'string',
      required: false,
    },
    DefaultCPC: {
      type: 'float',
      required: false,
    },
    ClicksDailyLimit: {
      type: 'int',
      required: false,
    },
    Title: {
      type: 'string',
      required: false,
    },
    Description: {
      type: 'string',
      required: false,
    },
    Description2: {
      type: 'string',
      required: false,
    },
    AdDomainBrand: {
      type: 'string',
      required: false,
    },
    DestinationUrl: {
      type: 'string',
      required: true,
    },
    RemoteChannel: {
      type: 'string',
      required: false,
    },
    Countries: {
      type: 'objectArray',
      required: false,
    },
    Os: {
      type: 'objectArray',
      required: false,
    },
    Browsers: {
      type: 'objectArray',
      required: false,
    },
    Searchfeeds: {
      type: 'intArray',
      required: false,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }
  // console.log(preparedData)
  // return false
  return apiClient.post(END_POINT + '/update', { Ad: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change Ad
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeAdStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Ad: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Duplicate existing Add
 *
 * @param {int} id - add id
 * @returns {Promise} - Request promise
 */
export async function cloneAd(id) {
  // todo add type check
  return apiClient.post(END_POINT + '/duplicate', {
    Ad: { Id: id },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
