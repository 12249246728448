var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',[_c('a-layout-content',[_c('div',{class:{
          [_vm.$style.container]: true,
          cui__layout__squaredBorders: _vm.settings.isSquaredBorders,
          cui__layout__cardsShadow: _vm.settings.isCardShadow,
          cui__layout__borderless: _vm.settings.isBorderless,
          [_vm.$style.white]: _vm.settings.authPagesColor === 'white',
          [_vm.$style.gray]: _vm.settings.authPagesColor === 'gray',
        },style:({backgroundImage: _vm.settings.authPagesColor === 'image' ? `url(resources/images/content/photos/7.jpg)` : 'none'})},[_c('div',{class:{
          [_vm.$style.topbar]: true,
          [_vm.$style.topbarGray]: _vm.settings.isGrayTopbar,
        }},[_c('div',{class:_vm.$style.logoContainer},[_c('div',{class:_vm.$style.logo},[_c('img',{staticClass:"mr-2",attrs:{"alt":"RTB","src":"resources/images/logo.svg"}}),_c('div',{class:_vm.$style.name},[_vm._v(_vm._s(_vm.settings.logo))]),(_vm.settings.logo === 'RTB.ME')?_c('div',{class:_vm.$style.descr}):_vm._e()])]),_c('div',{staticClass:"d-none d-sm-block"})]),_c('div',{class:_vm.$style.containerInner},[_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view')],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }