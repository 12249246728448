import Vue from 'vue'
import Vuex from 'vuex'
import { getBannerzones, createBannerzone, updateBannerzone, changeBannerzoneStatus, getBannerzonesForPublisher, getBannerZonesForUserAndPublisher } from '@/api/display/bannerzone'
import { getBannerzonesShort } from '../../../api/display/bannerzone'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_BANNERZONE(state, payload) {
      const bannerzone = state.list.find(item => item.Id === parseInt(payload.Id))
      Object.assign(bannerzone, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_BANNERZONES_FOR_PUBLISHER({ commit, dispatch, rootState }, payload) {
      getBannerzonesForPublisher(payload.PublisherId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    // combine user and publisher filters
    LOAD_BANNERZONES_FOR_USER_PUB({ commit, dispatch, rootState }, payload) {
      getBannerZonesForUserAndPublisher(payload.AccManagerId, payload.PublisherId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_BANNERZONE({ commit, dispatch, rootState }, id) {
      getBannerzones(id).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_BANNERZONES({ commit, dispatch, rootState }) {
      getBannerzones().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_BANNERZONES_SHORT({ commit, dispatch, rootState }) {
      getBannerzonesShort().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_BANNERZONE({ commit, dispatch, rootState }, { payload, callback }) {
      createBannerzone(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'Banner Zone Created',
              description: 'Banner Zone has been successfully created.',
            })
            callback()
          }
        }
        // TODO: add new banner zone to store object
        dispatch('LOAD_BANNERZONES')
      })
    },
    UPDATE_BANNERZONE({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateBannerzone({ id, ...payload }).then(response => {
        if (response) {
          // commit('SET_BANNERZONE', response.Bannerzone)
          Vue.prototype.$notification.success({
            message: 'Banner Zone Updated',
            description: 'Banner Zone has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeBannerzoneStatus(payload).then(response => {
        if (response) {
          commit('SET_BANNERZONE', response.Bannerzone)
          Vue.prototype.$notification.success({
            message: (response.Bannerzone.Status === 2 ? 'Banner Zone Deleted' : 'Status Changed'),
            description: (response.Bannerzone.Status === 2 ? 'Banner Zone successfully deleted.' : 'Banner Zone status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getBannerzoneData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getBannerzones: (state) => {
      return state.list
    },
    getActiveBannerzones: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
