import Vue from 'vue'
import Vuex from 'vuex'
import { getDspPopFeeds, createDspPopFeed, updateDspPopFeed, changeDspPopFeedStatus, getDspPopFeedsForAdvertiser, getDspPopFeedsForUserAndAdvertiser } from '@/api/display/dsppopfeed'
import { getDspPopFeedsShort } from '../../../api/display/dsppopfeed'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_FEED(state, payload) {
      const dsppopfeed = state.list.find(item => item.Id === parseInt(payload.Id))
      Object.assign(dsppopfeed, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_FEEDS_FOR_ADVERTISER({ commit, dispatch, rootState }, payload) {
      getDspPopFeedsForAdvertiser(payload.AdvertiserId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_FEEDS_FOR_USER_ADV({ commit, dispatch, rootState }, payload) {
      getDspPopFeedsForUserAndAdvertiser(payload.AccManagerId, payload.AdvertiserId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_FEEDS({ commit, dispatch, rootState }) {
      getDspPopFeeds().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_FEEDS_SHORT({ commit, dispatch, rootState }) {
      getDspPopFeedsShort().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_FEED({ commit, dispatch, rootState }, { payload }) {
      return new Promise((resolve, reject) => {
        createDspPopFeed(payload).then(response => {
          if (response) {
            if (typeof response.type !== 'undefined' && response.type === 'error') {
              Vue.prototype.$notification.error({
                message: 'Invalid data',
                description: response.message,
              })
              reject(response.message)
            } else if (response.Status === 'Ok') {
              Vue.prototype.$notification.success({
                message: 'DSP Pop Feed Created',
                description: 'DSP Pop Feed has been successfully created.',
              })
              resolve(response)
            }
          }
          dispatch('LOAD_FEEDS')
        }).catch(error => {
          reject(error)
        })
      })
    },
    UPDATE_FEED({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateDspPopFeed({ id, ...payload }).then(response => {
        if (response) {
          // commit('SET_FEED', response.Dsppopfeed)
          Vue.prototype.$notification.success({
            message: 'DSP Pop Feed Updated',
            description: 'DSP Pop Feed has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeDspPopFeedStatus(payload).then(response => {
        if (response) {
          commit('SET_FEED', response.Dsppopfeed)
          Vue.prototype.$notification.success({
            message: (response.Dsppopfeed.Status === 2 ? 'DSP Pop Feed Deleted' : 'Status Changed'),
            description: (response.Dsppopfeed.Status === 2 ? 'DSP Pop Feed successfully deleted.' : 'DSP Pop Feed status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getDspPopFeedData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getDspPopFeed: (state) => {
      return state.list
    },
    getActiveDspPopFeeds: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
