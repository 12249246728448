import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'
import { currentAccount } from '@/services/jwt'

Vue.use(Router)

const authorized = store.state.auth.authorized
const router = new Router({
  base: process.env.BASE_URL,
  // mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // {
    //   path: '/xml',
    //   name: 'xml-root',
    //   redirect: '/xml/dashboard',
    //   component: MainLayout,
    //   meta: {
    //     authRequired: true,
    //     hidden: true,
    //   },
    //   children: [
    //     // Publishers
    //     // -----------------------------------------
    //     // {
    //     //   path: '/xml/publishers',
    //     //   name: 'xml-publishers',
    //     //   meta: {
    //     //     title: 'Publishers',
    //     //   },
    //     //   component: () => import('./views/xml/publisher'),
    //     // },
    //     // {
    //     //   path: '/xml/publisher/create',
    //     //   name: 'xml-publisher-create',
    //     //   meta: {
    //     //     title: 'Create Publisher',
    //     //   },
    //     //   component: () => import('./views/xml/publisher/create'),
    //     // },
    //     // {
    //     //   path: '/xml/publisher/update/:id',
    //     //   name: 'xml-publisher-update',
    //     //   meta: {
    //     //     title: 'Edit Publisher',
    //     //   },
    //     //   component: () => import('./views/xml/publisher/update'),
    //     // },
    //     // {
    //     //   path: '/xml/publisher/:id/feeds',
    //     //   name: 'xml-publisher-feeds',
    //     //   meta: {
    //     //     title: 'Search Feeds',
    //     //   },
    //     //   component: () => import('./views/xml/publisher/feeds'),
    //     // },
    //     // Publishers Feeds
    //     // -----------------------------------------
    //     {
    //       path: '/xml/feeds',
    //       name: 'xml-feeds',
    //       meta: {
    //         title: 'Feeds',
    //       },
    //       component: () => import('./views/xml/searchfeed'),
    //     },
    //     {
    //       path: '/xml/feed/create/:publisherId',
    //       name: 'xml-feed-create-publisher',
    //       meta: {
    //         title: 'Create Feed',
    //       },
    //       component: () => import('./views/xml/searchfeed/create'),
    //     },
    //     {
    //       path: '/xml/feed/create',
    //       name: 'xml-feed-create',
    //       meta: {
    //         title: 'Create Feed',
    //       },
    //       component: () => import('./views/xml/searchfeed/create'),
    //     },
    //     {
    //       path: '/xml/feed/update/:id',
    //       name: 'xml-feed-update',
    //       meta: {
    //         title: 'Edit Feed',
    //       },
    //       component: () => import('./views/xml/searchfeed/update'),
    //     },
    //     // Campaigns
    //     // -----------------------------------------
    //     {
    //       path: '/xml/campaigns',
    //       name: 'xml-campaigns',
    //       meta: {
    //         title: 'Campaigns',
    //       },
    //       component: () => import('./views/xml/campaign'),
    //     },
    //     {
    //       path: '/xml/campaign/create',
    //       name: 'xml-campaign-create',
    //       meta: {
    //         title: 'Create Campaign',
    //       },
    //       component: () => import('./views/xml/campaign/create'),
    //     },
    //     {
    //       path: '/xml/campaign/update/:id',
    //       name: 'xml-campaign-update',
    //       meta: {
    //         title: 'Edit Campaign',
    //       },
    //       component: () => import('./views/xml/campaign/update'),
    //     },
    //     {
    //       path: '/xml/campaign/:id/ads',
    //       name: 'xml-campaign-ads',
    //       meta: {
    //         title: 'Campaign Ads',
    //       },
    //       component: () => import('./views/xml/campaign/ads'),
    //     },
    //     // Ads
    //     // -----------------------------------------
    //     {
    //       path: '/xml/ads',
    //       name: 'xml-ads',
    //       meta: {
    //         title: 'Ads',
    //       },
    //       component: () => import('./views/xml/ad'),
    //     },
    //     {
    //       path: '/xml/ad/create/:campaignId',
    //       name: 'xml-ad-create-id',
    //       meta: {
    //         title: 'Create Ad',
    //       },
    //       component: () => import('./views/xml/ad/create'),
    //     },
    //     // {
    //     //   path: '/xml/ad/create',
    //     //   name: 'xml-create-ad',
    //     //   meta: {
    //     //     title: 'Create Ad',
    //     //   },
    //     //   component: () => import('./views/xml/ad/create'),
    //     // },
    //     {
    //       path: '/xml/ad/update/:id',
    //       name: 'xml-ad-update',
    //       meta: {
    //         title: 'Edit Ad',
    //       },
    //       component: () => import('./views/xml/ad/update'),
    //     },
    //     // Keyword Lists
    //     // -----------------------------------------
    //     {
    //       path: '/xml/kwlist',
    //       name: 'xml-kwlist',
    //       meta: {
    //         title: 'Keyword Lists',
    //       },
    //       component: () => import('./views/xml/keywordList/index'),
    //     },
    //     {
    //       path: '/xml/kwlist/update/:id',
    //       name: 'xml-kwlist-update',
    //       meta: {
    //         title: 'Edit Keyword list',
    //       },
    //       component: () => import('./views/xml/keywordList/update'),
    //     },
    //     // IP Lists
    //     // -----------------------------------------
    //     // {
    //     //   path: '/xml/iplists',
    //     //   name: 'xml-iplists',
    //     //   meta: {
    //     //     title: 'IP Lists',
    //     //   },
    //     //   component: () => import('./views/xml/iplist'),
    //     // },
    //     // {
    //     //   path: '/xml/iplist/update/:id',
    //     //   name: 'xml-iplist-update',
    //     //   meta: {
    //     //     title: 'Edit IP list',
    //     //   },
    //     //   component: () => import('./views/xml/iplist/update'),
    //     // },
    //     {
    //       path: '/xml/ad/create',
    //       name: 'xml-ad-create',
    //       meta: {
    //         title: 'Create Ad',
    //       },
    //       component: () => import('./views/xml/ad/create'),
    //     },
    //     // Dashboards
    //     // -----------------------------------------
    //     {
    //       path: '/xml/dashboard',
    //       name: 'xml-dashboard',
    //       meta: {
    //         title: 'Dashboard',
    //       },
    //       component: () => import('./views/dashboard/xml'),
    //     },
    //     {
    //       path: '/dashboard/beta',
    //       meta: {
    //         title: 'Dashboard Beta',
    //       },
    //       component: () => import('./views/dashboard/beta'),
    //     },
    //     {
    //       path: '/dashboard/crypto',
    //       meta: {
    //         title: 'Dashboard Crypto',
    //       },
    //       component: () => import('./views/dashboard/crypto'),
    //     },
    //     {
    //       path: '/dashboard/gamma',
    //       meta: {
    //         title: 'Dashboard Gamma',
    //       },
    //       component: () => import('./views/dashboard/gamma'),
    //     },
    //     // Widgets
    //     {
    //       path: '/widgets/general',
    //       meta: {
    //         title: 'Widgets / General',
    //       },
    //       component: () => import('./views/widgets/general'),
    //     },
    //     {
    //       path: '/widgets/lists',
    //       meta: {
    //         title: 'Widgets / Lists',
    //       },
    //       component: () => import('./views/widgets/lists'),
    //     },
    //     {
    //       path: '/widgets/tables',
    //       meta: {
    //         title: 'Widgets / Tables',
    //       },
    //       component: () => import('./views/widgets/tables'),
    //     },
    //     {
    //       path: '/widgets/charts',
    //       meta: {
    //         title: 'Widgets / Charts',
    //       },
    //       component: () => import('./views/widgets/charts'),
    //     },
    //     // Apps
    //     {
    //       path: '/apps/calendar',
    //       meta: {
    //         title: 'Apps / Calendar',
    //       },
    //       component: () => import('./views/apps/calendar'),
    //     },
    //     {
    //       path: '/apps/digitalocean-create',
    //       meta: {
    //         title: 'Apps / DigitalOcean Create',
    //       },
    //       component: () => import('./views/apps/digitalocean-create'),
    //     },
    //     {
    //       path: '/apps/digitalocean-droplets',
    //       meta: {
    //         title: 'Apps / DigitalOcean Droplets',
    //       },
    //       component: () => import('./views/apps/digitalocean-droplets'),
    //     },
    //     {
    //       path: '/apps/gallery',
    //       meta: {
    //         title: 'Apps / Gallery',
    //       },
    //       component: () => import('./views/apps/gallery'),
    //     },
    //     {
    //       path: '/apps/github-discuss',
    //       meta: {
    //         title: 'Apps / GitHub Discuss',
    //       },
    //       component: () => import('./views/apps/github-discuss'),
    //     },
    //     {
    //       path: '/apps/github-explore',
    //       meta: {
    //         title: 'Apps / GitHub Explore',
    //       },
    //       component: () => import('./views/apps/github-explore'),
    //     },
    //     {
    //       path: '/apps/google-analytics',
    //       meta: {
    //         title: 'Apps / Google Analytics',
    //       },
    //       component: () => import('./views/apps/google-analytics'),
    //     },
    //     {
    //       path: '/apps/helpdesk-dashboard',
    //       meta: {
    //         title: 'Apps / Helpdesk Dashboard',
    //       },
    //       component: () => import('./views/apps/helpdesk-dashboard'),
    //     },
    //     {
    //       path: '/apps/jira-agile-board',
    //       meta: {
    //         title: 'Apps / Jira Agile Board',
    //       },
    //       component: () => import('./views/apps/jira-agile-board'),
    //     },
    //     {
    //       path: '/apps/jira-dashboard',
    //       meta: {
    //         title: 'Apps / Jira Dashboard',
    //       },
    //       component: () => import('./views/apps/jira-dashboard'),
    //     },
    //     {
    //       path: '/apps/mail',
    //       meta: {
    //         title: 'Apps / Mail',
    //       },
    //       component: () => import('./views/apps/mail'),
    //     },
    //     {
    //       path: '/apps/messaging',
    //       meta: {
    //         title: 'Apps / Mesagging',
    //       },
    //       component: () => import('./views/apps/messaging'),
    //     },
    //     {
    //       path: '/apps/profile',
    //       meta: {
    //         title: 'Apps / Profile',
    //       },
    //       component: () => import('./views/apps/profile'),
    //     },
    //     {
    //       path: '/apps/todoist-list',
    //       meta: {
    //         title: 'Apps / Todoist List',
    //       },
    //       component: () => import('./views/apps/todoist-list'),
    //     },
    //     {
    //       path: '/apps/wordpress-add',
    //       meta: {
    //         title: 'Apps / Wordpress Add',
    //       },
    //       component: () => import('./views/apps/wordpress-add'),
    //     },
    //     {
    //       path: '/apps/wordpress-post',
    //       meta: {
    //         title: 'Apps / Wordpress Post',
    //       },
    //       component: () => import('./views/apps/wordpress-post'),
    //     },
    //     {
    //       path: '/apps/wordpress-posts',
    //       meta: {
    //         title: 'Apps / Wordpress Posts',
    //       },
    //       component: () => import('./views/apps/wordpress-posts'),
    //     },
    //     // Cards
    //     {
    //       path: '/cards/basic-cards',
    //       meta: {
    //         title: 'Cards / Basic',
    //       },
    //       component: () => import('./views/cards/basic-cards'),
    //     },
    //     {
    //       path: '/cards/tabbed-cards',
    //       meta: {
    //         title: 'Cards / Tabbed',
    //       },
    //       component: () => import('./views/cards/tabbed-cards'),
    //     },
    //     // UI Kits
    //     {
    //       path: '/ui-kits/bootstrap',
    //       meta: {
    //         title: 'UI Kit / Bootstrap',
    //       },
    //       component: () => import('./views/ui-kits/bootstrap'),
    //     },
    //     {
    //       path: '/ui-kits/antd',
    //       meta: {
    //         title: 'UI Kit / Ant Design',
    //       },
    //       component: () => import('./views/ui-kits/antd'),
    //     },
    //     // Tables
    //     {
    //       path: '/tables/bootstrap',
    //       meta: {
    //         title: 'Tables / Bootstrap',
    //       },
    //       component: () => import('./views/tables/bootstrap'),
    //     },
    //     {
    //       path: '/tables/antd',
    //       meta: {
    //         title: 'Tables / Ant Design',
    //       },
    //       component: () => import('./views/tables/antd'),
    //     },
    //     // Charts
    //     {
    //       path: '/charts/chartistjs',
    //       meta: {
    //         title: 'Charts / Chartist.js',
    //       },
    //       component: () => import('./views/charts/chartistjs'),
    //     },
    //     {
    //       path: '/charts/chartjs',
    //       meta: {
    //         title: 'Charts / Chart.js',
    //       },
    //       component: () => import('./views/charts/chartjs'),
    //     },
    //     {
    //       path: '/charts/c3',
    //       meta: {
    //         title: 'Charts / C3 Charts',
    //       },
    //       component: () => import('./views/charts/c3'),
    //     },
    //     // Icons
    //     {
    //       path: '/icons/feather-icons',
    //       meta: {
    //         title: 'Icons / Feather',
    //       },
    //       component: () => import('./views/icons/feather-icons'),
    //     },
    //     {
    //       path: '/icons/fontawesome',
    //       meta: {
    //         title: 'Icons / Fontawesome',
    //       },
    //       component: () => import('./views/icons/fontawesome'),
    //     },
    //     {
    //       path: '/icons/linearicons-free',
    //       meta: {
    //         title: 'Icons / Linearicons Free',
    //       },
    //       component: () => import('./views/icons/linearicons-free'),
    //     },
    //     {
    //       path: '/icons/icomoon-free',
    //       meta: {
    //         title: 'Icons / Icomoon Free',
    //       },
    //       component: () => import('./views/icons/icomoon-free'),
    //     },
    //     // Advanced
    //     {
    //       path: '/advanced/form-examples',
    //       meta: {
    //         title: 'Advanced / Form Examples',
    //       },
    //       component: () => import('./views/advanced/form-examples'),
    //     },
    //     {
    //       path: '/advanced/email-templates',
    //       meta: {
    //         title: 'Advanced / Email Templates',
    //       },
    //       component: () => import('./views/advanced/email-templates'),
    //     },
    //     {
    //       path: '/advanced/utilities',
    //       meta: {
    //         title: 'Advanced / Utilities',
    //       },
    //       component: () => import('./views/advanced/utilities'),
    //     },
    //     {
    //       path: '/advanced/grid',
    //       meta: {
    //         title: 'Advanced / Grid',
    //       },
    //       component: () => import('./views/advanced/grid'),
    //     },
    //     {
    //       path: '/advanced/typography',
    //       meta: {
    //         title: 'Advanced / Typography',
    //       },
    //       component: () => import('./views/advanced/typography'),
    //     },
    //     {
    //       path: '/advanced/pricing-tables',
    //       meta: {
    //         title: 'Advanced / Pricing Tables',
    //       },
    //       component: () => import('./views/advanced/pricing-tables'),
    //     },
    //     {
    //       path: '/advanced/invoice',
    //       meta: {
    //         title: 'Advanced / Invoice',
    //       },
    //       component: () => import('./views/advanced/invoice'),
    //     },
    //     {
    //       path: '/advanced/colors',
    //       meta: {
    //         title: 'Advanced / Colors',
    //       },
    //       component: () => import('./views/advanced/colors'),
    //     },
    //     {
    //       path: '/xml/reports/searchfeed',
    //       name: 'xml-reports-searchfeed',
    //       meta: {
    //         title: 'Reports By Feed',
    //       },
    //       component: () => import('./views/xml/reports/searchfeed'),
    //     },
    //     {
    //       path: '/xml/reports/ad',
    //       name: 'xml-reports-ad',
    //       meta: {
    //         title: 'Reports By Ad',
    //       },
    //       component: () => import('./views/xml/reports/ad'),
    //     },
    //     {
    //       path: '/xml/reports/campaign',
    //       name: 'xml-reports-campaign',
    //       meta: {
    //         title: 'Reports By Campaign',
    //       },
    //       component: () => import('./views/xml/reports/campaign'),
    //     },
    //     {
    //       path: '/xml/reports/keywords',
    //       name: 'xml-reports-keywords',
    //       meta: {
    //         title: 'Reports for Keywords',
    //       },
    //       component: () => import('./views/xml/reports/keywords'),
    //     },
    //     {
    //       path: '/xml/reports/subid',
    //       name: 'xml-reports-subid',
    //       meta: {
    //         title: 'Reports for Sub ID',
    //       },
    //       component: () => import('./views/xml/reports/subid'),
    //     },
    //   ],
    // },
    // DISPLAY MODULE BELOW
    {
      path: '/display',
      name: 'display-root',
      redirect: '/dashboard',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // Team
        // -----------------------------------------
        {
          path: '/team',
          name: 'team',
          meta: {
            title: 'Team',
          },
          component: () => import('./views/team'),
        },
        // Filter
        // -----------------------------------------
        // {
        //   path: '/filters',
        //   name: 'filters',
        //   meta: {
        //     title: 'Filters',
        //   },
        //   component: () => import('./views/filter'),
        // },
        // Users
        // -----------------------------------------
        {
          path: '/users',
          name: 'users',
          meta: {
            title: 'Users',
          },
          component: () => import('./views/user'),
        },
        {
          path: '/user/create',
          name: 'user-create',
          meta: {
            title: 'Create User',
          },
          component: () => import('./views/user/create'),
        },
        {
          path: '/user/update/:id',
          name: 'user-update',
          meta: {
            title: 'Edit User',
          },
          component: () => import('./views/user/update'),
        },
        {
          path: '/user/account/',
          name: 'user-account',
          meta: {
            title: 'Edit Account',
          },
          component: () => import('./views/user/account'),
        },
        {
          path: '/user/notifications/',
          name: 'user-notifications',
          meta: {
            title: 'Notifications',
          },
          component: () => import('./views/user/notifications'),
        },
        {
          path: '/user/update/:id',
          meta: {
            title: 'Edit User',
          },
          component: () => import('./views/user/update'),
        },
        {
          path: '/user/account/',
          meta: {
            title: 'Edit Account',
          },
          component: () => import('./views/user/account'),
        },
        // Keyword Reports
        // -----------------------------------------
        // {
        //   path: '/reports/keywords',
        //   name: 'display-reports-keywords',
        //   meta: {
        //     title: 'Reports for Keywords',
        //   },
        //   component: () => import('./views/display/reports/keywords'),
        // },

        // Keyword Lists
        // -----------------------------------------
        {
          path: '/kwlist',
          name: 'kwlist',
          meta: {
            title: 'Keyword Lists',
          },
          component: () => import('./views/xml/keywordList/index'),
        },
        {
          path: '/kwlist/update/:id',
          name: 'kwlist-update',
          meta: {
            title: 'Edit Keyword list',
          },
          component: () => import('./views/xml/keywordList/update'),
        },
        // Publishers
        // -----------------------------------------
        {
          path: '/publishers',
          name: 'publishers',
          meta: {
            title: 'Publishers',
          },
          component: () => import('./views/display/publisher'),
        },
        {
          path: '/publisher/create',
          name: 'publisher-create',
          meta: {
            title: 'Create Publisher',
          },
          component: () => import('./views/display/publisher/create'),
        },
        {
          path: '/publisher/update/:id',
          name: 'publisher-update',
          meta: {
            title: 'Edit Publisher',
          },
          component: () => import('./views/display/publisher/update'),
        },
        {
          path: '/publisher/:id/bannerzones',
          name: 'publisher-bannerzones',
          meta: {
            title: 'Publisher Banner Zones',
          },
          component: () => import('./views/display/publisher/bannerzones'),
        },
        {
          path: '/publisher/:id/videozones',
          name: 'publisher-videozones',
          meta: {
            title: 'Publisher Video Zones',
          },
          component: () => import('./views/display/publisher/videozones'),
        },
        {
          path: '/publisher/:id/rtbzones',
          name: 'publisher-rtbzones',
          meta: {
            title: 'Publisher RTB Zones',
          },
          component: () => import('./views/display/publisher/rtbzones'),
        },
        // Publishers Feeds
        // -----------------------------------------
        {
          path: '/bannerzones',
          name: 'bannerzones',
          meta: {
            title: 'Banner Zones',
          },
          component: () => import('./views/display/bannerzone'),
        },
        {
          path: '/bannerzone/create/:publisherId',
          name: 'create-bannerzone-pubId',
          meta: {
            title: 'Create Banner Zone',
          },
          component: () => import('./views/display/bannerzone/create'),
        },
        {
          path: '/rtbzone/create/:publisherId',
          name: 'create-rtbzone-pubId',
          meta: {
            title: 'Create RTB Zone',
          },
          component: () => import('./views/display/rtbzone/create'),
        },
        {
          path: '/bannerzone/create',
          name: 'bannerzone-create',
          meta: {
            title: 'Create Banner Zone',
          },
          component: () => import('./views/display/bannerzone/create'),
        },
        {
          path: '/bannerzone/update/:id',
          name: 'bannerzone-update',
          meta: {
            title: 'Edit Banner Zone',
          },
          component: () => import('./views/display/bannerzone/update'),
        },
        // Video Zones
        // -----------------------------------------
        {
          path: '/videozones',
          name: 'videozones',
          meta: {
            title: 'Video Zones',
          },
          component: () => import('./views/display/videozone'),
        },
        {
          path: '/videozone/create',
          name: 'videozone-create',
          meta: {
            title: 'Create Video Zone',
          },
          component: () => import('./views/display/videozone/create'),
        },
        {
          path: '/videozone/create/:publisherId',
          name: 'create-videozone-pubId',
          meta: {
            title: 'Create Banner',
          },
          component: () => import('./views/display/videozone/create'),
        },
        {
          path: '/videozone/update/:id',
          name: 'videozone-update',
          meta: {
            title: 'Edit Video Zone',
          },
          component: () => import('./views/display/videozone/update'),
        },
        // RTB Zones
        {
          path: '/rtbzones',
          name: 'rtbzones',
          meta: {
            title: 'RTB Zones',
          },
          component: () => import('./views/display/rtbzone'),
        },
        {
          path: '/rtbzone/create',
          name: 'rtbzone-create',
          meta: {
            title: 'Create RTB Zone',
          },
          component: () => import('./views/display/rtbzone/create'),
        },
        {
          path: '/rtbzone/update/:id',
          name: 'rtbzone-update',
          meta: {
            title: 'Edit RTB Zone',
          },
          component: () => import('./views/display/rtbzone/update'),
        },
        // Advertisers+
        // -----------------------------------------
        {
          path: '/advertisers',
          name: 'advertisers',
          meta: {
            title: 'Advertisers',
          },
          component: () => import('./views/display/advertiser'),
        },
        {
          path: '/advertiser/create',
          name: 'advertiser-create',
          meta: {
            title: 'Create Advertiser',
          },
          component: () => import('./views/display/advertiser/create'),
        },
        {
          path: '/advertiser/update/:id',
          name: 'advertiser-update',
          meta: {
            title: 'Edit Advertiser',
          },
          component: () => import('./views/display/advertiser/update'),
        },
        // Image Library
        {
          path: '/library/images',
          name: 'library-images',
          meta: {
            title: 'Image Library',
          },
          component: () => import('./views/display/creative/imagelibrary'),
        },
        // Video Library
        {
          path: '/library/videos',
          name: 'library-videos',
          meta: {
            title: 'Video Library',
          },
          component: () => import('./views/display/creative/videolibrary'),
        },
        // Sites
        {
          path: '/library/sites',
          name: 'library-sites',
          meta: {
            title: 'Sites Library',
          },
          component: () => import('./views/display/Sites/'),
        },
        // Apps
        {
          path: '/library/apps',
          name: 'library-apps',
          meta: {
            title: 'Apps Library',
          },
          component: () => import('./views/display/Apps/'),
        },
        // CRIDs
        {
          path: '/library/crids',
          name: 'library-crids',
          meta: {
            title: 'CRIDs Library',
          },
          component: () => import('./views/display/Crids/index.vue'),
        },
        // Edit Creative
        {
          path: '/creative/update/:id',
          name: 'creative-update',
          meta: {
            title: 'Edit Creative',
          },
          component: () => import('./views/display/creative/update.vue'),
        },
        // Campaigns
        // -----------------------------------------
        {
          path: '/campaigns',
          name: 'campaigns',
          meta: {
            title: 'Campaigns',
          },
          component: () => import('./views/display/campaign'),
        },
        {
          path: '/campaign/create',
          name: 'campaign-create',
          meta: {
            title: 'Create Campaign',
          },
          component: () => import('./views/display/campaign/create'),
        },
        {
          path: '/campaign/update/:id',
          name: 'campaign-update',
          meta: {
            title: 'Edit Campaign',
          },
          component: () => import('./views/display/campaign/update'),
        },
        // {
        //   path: '/campaign/:id/ads',
        //   name: '',
        //   meta: {
        //     title: 'Campaign ads',
        //   },
        //   component: () => import('./views/display/campaign/ads'),
        // },
        {
          path: '/campaign/:id/bannerads',
          name: 'campaign-bannerads',
          meta: {
            title: 'Campaign Banner Ads',
          },
          component: () => import('./views/display/campaign/bannerads'),
        },
        // Banner Ads
        // -----------------------------------------
        {
          path: '/bannerads',
          name: 'bannerads',
          meta: {
            title: 'Banner Ads',
          },
          component: () => import('./views/display/bannerad'),
        },
        {
          path: '/bannerad/create/:campaignId',
          name: 'create-bannerad-campaignId',
          meta: {
            title: 'Create Banner Ad',
          },
          component: () => import('./views/display/bannerad/create'),
        },
        {
          path: '/bannerad/create',
          name: 'bannerad-create',
          meta: {
            title: 'Create Banner Ad',
          },
          component: () => import('./views/display/bannerad/create'),
        },
        {
          path: '/bannerad/update/:id',
          name: 'bannerad-update',
          meta: {
            title: 'Edit Banner Ad',
          },
          component: () => import('./views/display/bannerad/update'),
        },
        // Video Ads
        // -----------------------------------------
        {
          path: '/videoads',
          name: 'videoads',
          meta: {
            title: 'Video Ads',
          },
          component: () => import('./views/display/videoad'),
        },
        {
          path: '/videoad/create/:campaignId',
          name: 'create-videoad-campaignId',
          meta: {
            title: 'Create Video Ad',
          },
          component: () => import('./views/display/videoad/create'),
        },
        {
          path: '/videoad/create',
          name: 'videoad-create',
          meta: {
            title: 'Create Video Ad',
          },
          component: () => import('./views/display/videoad/create'),
        },
        {
          path: '/videoad/update/:id',
          name: 'videoad-update',
          meta: {
            title: 'Edit Video Ad',
          },
          component: () => import('./views/display/videoad/update'),
        },
        {
          path: '/campaign/:id/videoads',
          name: 'campaign-videoads',
          meta: {
            title: 'Campaign Video Ads',
          },
          component: () => import('./views/display/campaign/videoads'),
        },
        // Native Ads
        // -----------------------------------------
        {
          path: '/nativeads',
          name: 'nativeads',
          meta: {
            title: 'Native Ads',
          },
          component: () => import('./views/display/nativead'),
        },
        {
          path: '/nativead/create/:campaignId',
          name: 'create-nativead-campaignId',
          meta: {
            title: 'Create Native Ad',
          },
          component: () => import('./views/display/nativead/create'),
        },
        {
          path: '/nativead/create',
          name: 'nativead-create',
          meta: {
            title: 'Create Native Ad',
          },
          component: () => import('./views/display/nativead/create'),
        },
        {
          path: '/nativead/update/:id',
          name: 'nativead-update',
          meta: {
            title: 'Edit Native Ad',
          },
          component: () => import('./views/display/nativead/update'),
        },
        {
          path: '/campaign/:id/nativeads',
          name: 'campaign-nativeads',
          meta: {
            title: 'Campaign Native Ads',
          },
          component: () => import('./views/display/campaign/nativeads'),
        },
        // Remote RTB Zones
        // -----------------------------------------
        {
          path: '/remotertbzones',
          name: 'remotertbzones',
          meta: {
            title: 'Remote RTB Zones',
          },
          component: () => import('./views/display/remotertbzone'),
        },
        {
          path: '/remotertbzone/create/:advertiserId',
          name: 'create-remotertbzone-advertiserId',
          meta: {
            title: 'Create Remote RTB Zone',
          },
          component: () => import('./views/display/remotertbzone/create'),
        },
        {
          path: '/remotertbzone/create',
          name: 'remotertbzone-create',
          meta: {
            title: 'Create Remote RTB Zone',
          },
          component: () => import('./views/display/remotertbzone/create'),
        },
        {
          path: '/remotertbzone/update/:id',
          name: 'remotertbzone-update',
          meta: {
            title: 'Edit Remote RTB Zone',
          },
          component: () => import('./views/display/remotertbzone/update'),
        },
        {
          path: '/advertiser/:id/remotertbzones',
          name: 'advertiser-remotertbzones',
          meta: {
            title: 'Advertiser Remote RTB Zones',
          },
          component: () => import('./views/display/advertiser/remotertbzones'),
        },
        // Remote Video Zones
        // -----------------------------------------
        {
          path: '/remotevideozones',
          name: 'remotevideozones',
          meta: {
            title: 'Remote Video Zones',
          },
          component: () => import('./views/display/remotevideozone'),
        },
        {
          path: '/remotevideozone/create/:advertiserId',
          name: 'create-remotevideozone-advertiserId',
          meta: {
            title: 'Create Remote Video Zone',
          },
          component: () => import('./views/display/remotevideozone/create'),
        },
        {
          path: '/remotevideozone/create',
          name: 'remotevideozone-create',
          meta: {
            title: 'Create Remote Video Zone',
          },
          component: () => import('./views/display/remotevideozone/create'),
        },
        // XML DSP Pop Feeds
        // -----------------------------------------
        {
          path: '/dsppopfeeds',
          name: 'dsppopfeeds',
          meta: {
            title: 'XML DSP Pop Feeds',
          },
          component: () => import('./views/display/dsppopfeed'),
        },
        {
          path: '/dsppopfeed/create/:advertiserId',
          name: 'create-dsppopfeed-advertiserId',
          meta: {
            title: 'Create DSP Pop Feeds',
          },
          component: () => import('./views/display/dsppopfeed/create'),
        },
        {
          path: '/dsppopfeed/create',
          name: 'dsppopfeed-create',
          meta: {
            title: 'Create DSP Pop Feeds',
          },
          component: () => import('./views/display/dsppopfeed/create'),
        },
        {
          path: '/dsppopfeed/update/:id',
          name: 'dsppopfeed-update',
          meta: {
            title: 'Update DSP Pop Feeds',
          },
          component: () => import('./views/display/dsppopfeed/update'),
        },
        {
          path: '/advertiser/:id/dsppopfeeds',
          name: 'advertiser-dsppopfeeds',
          meta: {
            title: 'Advertiser DSP Pop Feeds',
          },
          component: () => import('./views/display/advertiser/dsppopfeeds'),
        },
        // XML Pop Feeds
        // -----------------------------------------
        {
          path: '/popfeeds',
          name: 'popfeeds',
          meta: {
            title: 'XML Pop Feeds',
          },
          component: () => import('./views/display/popfeed'),
        },
        {
          path: '/popfeed/create/:publisherId',
          name: 'create-popfeed-publisherId',
          meta: {
            title: 'Create Pop Feeds',
          },
          component: () => import('./views/display/popfeed/create'),
        },
        {
          path: '/popfeed/create',
          name: 'popfeed-create',
          meta: {
            title: 'Create Pop Feeds',
          },
          component: () => import('./views/display/popfeed/create'),
        },
        {
          path: '/popfeed/update/:id',
          name: 'popfeed-update',
          meta: {
            title: 'Update  Pop Feeds',
          },
          component: () => import('./views/display/popfeed/update'),
        },
        {
          path: '/publisher/:id/popfeeds',
          name: 'publisher-popfeeds',
          meta: {
            title: 'Publisher Pop Feeds',
          },
          component: () => import('./views/display/publisher/popfeeds'),
        },
        {
          path: '/advertiser/:id/campaigns',
          name: 'advertiser-campaigns',
          meta: {
            title: 'Advertiser Campaigns',
          },
          component: () => import('./views/display/advertiser/campaigns'),
        },
        {
          path: '/campaign/create/:advertiserId',
          name: 'create-campaign-advertiserId',
          meta: {
            title: 'Create Campaign',
          },
          component: () => import('./views/display/campaign/create'),
        },
        {
          path: '/advertiser/:id/remotevideozones',
          name: 'advertiser-remotevideozones',
          meta: {
            title: 'Advertiser Remote Video Zones',
          },
          component: () => import('./views/display/advertiser/remotevideozones'),
        },
        {
          path: '/remotevideozone/update/:id',
          name: 'remotevideozone-update',
          meta: {
            title: 'Edit Remote Video Zone',
          },
          component: () => import('./views/display/remotevideozone/update'),
        },
        // {
        //   path: '/campaign/:id/remotevideozones',
        //   name: 'display-campaign-remotevideozones',
        //   meta: {
        //     title: 'Campaign Remote Video Zones',
        //   },
        //   component: () => import('./views/display/campaign/remotevideozones'),
        // },
        // IP Lists
        // -----------------------------------------
        {
          path: '/iplists',
          name: 'iplists',
          meta: {
            title: 'IP Lists',
          },
          component: () => import('./views/display/iplist'),
        },
        {
          path: '/iplist/update/:id',
          name: 'iplist-update',
          meta: {
            title: 'Edit IP list',
          },
          component: () => import('./views/display/iplist/update'),
        },
        // App Lists
        // -----------------------------------------
        {
          path: '/applists',
          name: 'applists',
          meta: {
            title: 'App Lists',
          },
          component: () => import('./views/display/applist'),
        },
        {
          path: '/applist/update/:id',
          name: 'applist-update',
          meta: {
            title: 'Edit App list',
          },
          component: () => import('./views/display/applist/update'),
        },
        // Domain Lists
        // -----------------------------------------
        {
          path: '/domainlists',
          name: 'domainlists',
          meta: {
            title: 'Domain Lists',
          },
          component: () => import('./views/display/domainlist'),
        },
        {
          path: '/domainlist/update/:id',
          name: 'domainlist-update',
          meta: {
            title: 'Edit Domain list',
          },
          component: () => import('./views/display/domainlist/update'),
        },
        // Dashboards
        // -----------------------------------------
        {
          path: '/dashboard',
          name: 'dashboard',
          meta: {
            title: 'Dashboard',
          },
          component: () => import('./views/dashboard/display'),
        },
        {
          path: '/dashboard/beta',
          meta: {
            title: 'Dashboard Beta',
          },
          component: () => import('./views/dashboard/beta'),
        },
        {
          path: '/dashboard/crypto',
          meta: {
            title: 'Dashboard Crypto',
          },
          component: () => import('./views/dashboard/crypto'),
        },
        {
          path: '/dashboard/gamma',
          meta: {
            title: 'Dashboard Gamma',
          },
          component: () => import('./views/dashboard/gamma'),
        },
        // Widgets
        {
          path: '/widgets/general',
          meta: {
            title: 'Widgets / General',
          },
          component: () => import('./views/widgets/general'),
        },
        {
          path: '/widgets/lists',
          meta: {
            title: 'Widgets / Lists',
          },
          component: () => import('./views/widgets/lists'),
        },
        {
          path: '/widgets/tables',
          meta: {
            title: 'Widgets / Tables',
          },
          component: () => import('./views/widgets/tables'),
        },
        {
          path: '/widgets/charts',
          meta: {
            title: 'Widgets / Charts',
          },
          component: () => import('./views/widgets/charts'),
        },
        // Apps
        {
          path: '/apps/calendar',
          meta: {
            title: 'Apps / Calendar',
          },
          component: () => import('./views/apps/calendar'),
        },
        {
          path: '/apps/digitalocean-create',
          meta: {
            title: 'Apps / DigitalOcean Create',
          },
          component: () => import('./views/apps/digitalocean-create'),
        },
        {
          path: '/apps/digitalocean-droplets',
          meta: {
            title: 'Apps / DigitalOcean Droplets',
          },
          component: () => import('./views/apps/digitalocean-droplets'),
        },
        {
          path: '/apps/gallery',
          meta: {
            title: 'Apps / Gallery',
          },
          component: () => import('./views/apps/gallery'),
        },
        {
          path: '/apps/github-discuss',
          meta: {
            title: 'Apps / GitHub Discuss',
          },
          component: () => import('./views/apps/github-discuss'),
        },
        {
          path: '/apps/github-explore',
          meta: {
            title: 'Apps / GitHub Explore',
          },
          component: () => import('./views/apps/github-explore'),
        },
        {
          path: '/apps/google-analytics',
          meta: {
            title: 'Apps / Google Analytics',
          },
          component: () => import('./views/apps/google-analytics'),
        },
        {
          path: '/apps/helpdesk-dashboard',
          meta: {
            title: 'Apps / Helpdesk Dashboard',
          },
          component: () => import('./views/apps/helpdesk-dashboard'),
        },
        {
          path: '/apps/jira-agile-board',
          meta: {
            title: 'Apps / Jira Agile Board',
          },
          component: () => import('./views/apps/jira-agile-board'),
        },
        {
          path: '/apps/jira-dashboard',
          meta: {
            title: 'Apps / Jira Dashboard',
          },
          component: () => import('./views/apps/jira-dashboard'),
        },
        {
          path: '/apps/mail',
          meta: {
            title: 'Apps / Mail',
          },
          component: () => import('./views/apps/mail'),
        },
        {
          path: '/apps/messaging',
          meta: {
            title: 'Apps / Mesagging',
          },
          component: () => import('./views/apps/messaging'),
        },
        {
          path: '/apps/profile',
          meta: {
            title: 'Apps / Profile',
          },
          component: () => import('./views/apps/profile'),
        },
        {
          path: '/apps/todoist-list',
          meta: {
            title: 'Apps / Todoist List',
          },
          component: () => import('./views/apps/todoist-list'),
        },
        {
          path: '/apps/wordpress-add',
          meta: {
            title: 'Apps / Wordpress Add',
          },
          component: () => import('./views/apps/wordpress-add'),
        },
        {
          path: '/apps/wordpress-post',
          meta: {
            title: 'Apps / Wordpress Post',
          },
          component: () => import('./views/apps/wordpress-post'),
        },
        {
          path: '/apps/wordpress-posts',
          meta: {
            title: 'Apps / Wordpress Posts',
          },
          component: () => import('./views/apps/wordpress-posts'),
        },
        // Cards
        {
          path: '/cards/basic-cards',
          meta: {
            title: 'Cards / Basic',
          },
          component: () => import('./views/cards/basic-cards'),
        },
        {
          path: '/cards/tabbed-cards',
          meta: {
            title: 'Cards / Tabbed',
          },
          component: () => import('./views/cards/tabbed-cards'),
        },
        // UI Kits
        {
          path: '/ui-kits/bootstrap',
          meta: {
            title: 'UI Kit / Bootstrap',
          },
          component: () => import('./views/ui-kits/bootstrap'),
        },
        {
          path: '/ui-kits/antd',
          meta: {
            title: 'UI Kit / Ant Design',
          },
          component: () => import('./views/ui-kits/antd'),
        },
        // Tables
        {
          path: '/tables/bootstrap',
          meta: {
            title: 'Tables / Bootstrap',
          },
          component: () => import('./views/tables/bootstrap'),
        },
        {
          path: '/tables/antd',
          meta: {
            title: 'Tables / Ant Design',
          },
          component: () => import('./views/tables/antd'),
        },
        // Charts
        {
          path: '/charts/chartistjs',
          meta: {
            title: 'Charts / Chartist.js',
          },
          component: () => import('./views/charts/chartistjs'),
        },
        {
          path: '/charts/chartjs',
          meta: {
            title: 'Charts / Chart.js',
          },
          component: () => import('./views/charts/chartjs'),
        },
        {
          path: '/charts/c3',
          meta: {
            title: 'Charts / C3 Charts',
          },
          component: () => import('./views/charts/c3'),
        },
        // Icons
        {
          path: '/icons/feather-icons',
          meta: {
            title: 'Icons / Feather',
          },
          component: () => import('./views/icons/feather-icons'),
        },
        {
          path: '/icons/fontawesome',
          meta: {
            title: 'Icons / Fontawesome',
          },
          component: () => import('./views/icons/fontawesome'),
        },
        {
          path: '/icons/linearicons-free',
          meta: {
            title: 'Icons / Linearicons Free',
          },
          component: () => import('./views/icons/linearicons-free'),
        },
        {
          path: '/icons/icomoon-free',
          meta: {
            title: 'Icons / Icomoon Free',
          },
          component: () => import('./views/icons/icomoon-free'),
        },
        // Advanced
        {
          path: '/advanced/form-examples',
          meta: {
            title: 'Advanced / Form Examples',
          },
          component: () => import('./views/advanced/form-examples'),
        },
        {
          path: '/advanced/email-templates',
          meta: {
            title: 'Advanced / Email Templates',
          },
          component: () => import('./views/advanced/email-templates'),
        },
        {
          path: '/advanced/utilities',
          meta: {
            title: 'Advanced / Utilities',
          },
          component: () => import('./views/advanced/utilities'),
        },
        {
          path: '/advanced/grid',
          meta: {
            title: 'Advanced / Grid',
          },
          component: () => import('./views/advanced/grid'),
        },
        {
          path: '/advanced/typography',
          meta: {
            title: 'Advanced / Typography',
          },
          component: () => import('./views/advanced/typography'),
        },
        {
          path: '/advanced/pricing-tables',
          meta: {
            title: 'Advanced / Pricing Tables',
          },
          component: () => import('./views/advanced/pricing-tables'),
        },
        {
          path: '/advanced/invoice',
          meta: {
            title: 'Advanced / Invoice',
          },
          component: () => import('./views/advanced/invoice'),
        },
        {
          path: '/advanced/colors',
          meta: {
            title: 'Advanced / Colors',
          },
          component: () => import('./views/advanced/colors'),
        },
        {
          path: '/reports/zone',
          name: 'reports-zone',
          meta: {
            title: 'Reports By Zone',
          },
          component: () => import('./views/display/reports/zone'),
        },
        {
          path: '/reports/ad',
          name: 'reports-ad',
          meta: {
            title: 'Reports By Ad',
          },
          component: () => import('./views/display/reports/ad'),
        },
        // {
        //   path: '/reports/campaign',
        //   name: 'display-reports-campaign',
        //   meta: {
        //     title: 'Reports By Campaign',
        //   },
        //   component: () => import('./views/display/reports/campaign'),
        // },
        {
          path: '/reports/seatscrids',
          name: 'reports-seatscrids',
          meta: {
            title: 'Seats/CRIDs',
          },
          component: () => import('./views/display/reports/seatsCrids'),
        },
        {
          path: '/reports/video',
          name: 'reports-video',
          meta: {
            title: 'Reports for Video',
          },
          component: () => import('./views/display/reports/video'),
        },
        {
          path: '/reports/error',
          name: 'reports-error',
          meta: {
            title: 'Reports for Errors',
          },
          component: () => import('./views/display/reports/error'),
        },
        {
          path: '/reports/schain',
          name: 'reports-schain',
          meta: {
            title: 'Reports for SChain Errors',
          },
          component: () => import('./views/display/reports/schain'),
        },
        {
          path: '/reports/ivt',
          name: 'reports-ivt',
          meta: {
            title: 'Reports for IVT',
          },
          component: () => import('./views/display/reports/ivt'),
        },
        {
          path: '/reports/geoedge',
          name: 'reports-geoedge',
          meta: {
            title: 'Geoedge Alerts',
          },
          component: () => import('./views/display/reports/geoedge'),
        },
        // {
        //   path: '/reports/apps/zone',
        //   name: 'reports-apps-zone',
        //   meta: {
        //     title: 'Apps By Zone',
        //   },
        //   component: () => import('./views/display/appReports/zone'),
        // },
        // {
        //   path: '/reports/apps/ad',
        //   name: 'reports-apps-ad',
        //   meta: {
        //     title: 'Apps By Ad',
        //   },
        //   component: () => import('./views/display/appReports/ad'),
        // },
        // {
        //   path: '/reports/sites/zone',
        //   name: 'reports-sites-zone',
        //   meta: {
        //     title: 'Sites By Zone',
        //   },
        //   component: () => import('./views/display/siteReports/zone'),
        // },
        // {
        //   path: '/reports/sites/ad',
        //   name: 'reports-sites-ad',
        //   meta: {
        //     title: 'Sites By Ad',
        //   },
        //   component: () => import('./views/display/siteReports/ad'),
        // },
        // {
        //   path: '/reports/sizes/zone',
        //   name: 'reports-sizes-zone',
        //   meta: {
        //     title: 'Sizes By Zone',
        //   },
        //   component: () => import('./views/display/sizeReports/zone'),
        // },
        // {
        //   path: '/reports/sizes/ad',
        //   name: 'reports-sizes-ad',
        //   meta: {
        //     title: 'Sizes By Ad',
        //   },
        //   component: () => import('./views/display/sizeReports/ad'),
        // },
        // {
        //   path: '/reports/pub/zone',
        //   name: 'reports-pub-zone',
        //   meta: {
        //     title: 'Pub IDs By Zone',
        //   },
        //   component: () => import('./views/display/pubReports/zone'),
        // },
        // {
        //   path: '/reports/pub/ad',
        //   name: 'reports-pub-ad',
        //   meta: {
        //     title: 'Pub IDs By Ad',
        //   },
        //   component: () => import('./views/display/pubReports/ad'),
        // },
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/registration-pending',
          meta: {
            title: 'Registration Pending',
          },
          component: () => import('./views/landing/register-pending'),
        },
        {
          path: '/auth/404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          name: 'auth-login',
          meta: {
            title: 'Sign In',
            conditionalRoute: !authorized,
          },
          component: () => import('./views/auth/login'),
          // beforeEnter: (to, from, next) => {
          //   if (store.getters['auth/user'].authorized) {
          //     console.log('authorized')
          //     // next(false)
          //     // from(false)
          //   } else {
          //     console.log('NOT authorized')
          //   }
          //   // console.log(store.getters['auth/user'].authorized, 'store.auth')
          // },
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },
    // Redirect to 404
    {
      path: '*', redirect: 'auth/404', hidden: true,
    },
    {
      path: '/',
      name: 'root',
      redirect: authorized ? '/auth/login' : false,
      beforeEnter: (to, from, next) => {
        authorized ? to({ name: 'auth-login' }) : next({ name: 'dashboard' })
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.path.indexOf('/xml') === 0) {
    store.state.settings.platform = 1
  } else if (to.path.indexOf('/display') === 0) {
    store.state.settings.platform = 0
  }
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.auth.authorized) {
      store.dispatch('auth/LOAD_CURRENT_ACCOUNT')
      currentAccount().then(response => {
        if (response && to.path !== '/#/auth/login') {
          store.dispatch('auth/LOAD_CURRENT_ACCOUNT')
          next()
        } else if (next.conditionalRoute) {
          next({
            // vrati xml, ako vratis xml
            path: '/dashboard',
            query: { redirect: to.fullPath },
          })
        } else {
          next({
            path: '/auth/login',
            query: { redirect: to.fullPath },
          })
        }
      }).catch(err => console.log(err))
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
