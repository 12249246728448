import apiClient from '@/services/axios'

const END_POINT = '/ortb/campaign'

/**
 * Get user's team info
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getCampaigns(id = null) {
  const Id = id != null ? { Id: id } : null
  return apiClient.get(END_POINT + '/get', { params: Id }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getCampaignsShort(id = null) {
  const Id = id != null ? { Id: id } : null
  return apiClient.get(END_POINT + '/get/short', { params: Id }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getCampaignsForUser(accManagerId) {
  return apiClient.get(END_POINT + '/get', {
    params: { AccManagerId: accManagerId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getCampaignsForAdvertiser(advertiserId) {
  return apiClient.get(END_POINT + '/get', {
    params: { AdvertiserId: advertiserId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new campaign
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createCampaign(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    AdvertiserId: {
      type: 'int',
      required: true,
    },
    Name: {
      type: 'string',
      required: true,
    },
    ClicksDailyLimit: {
      type: 'int',
      required: false,
      map: 'clicks',
    },
    ClicksLimitPerIp: {
      type: 'int',
      required: false,
      map: 'limit',
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/add', { Campaign: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Duplicate existing campaign
 *
 * @param {int} id - campaign id
 * @param {int} WithBannerads
 * @param {int} WithVideoads
 * @param {int} WithRemotertbzones
 * @returns {Promise} - Request promise
 */
export async function cloneCampaign(id, WithBannerads, WithVideoads, WithRemotertbzones, WithRemotevideozones, WithNativeads) {
  // todo add type check
  return apiClient.post(END_POINT + '/duplicate', {
    Campaign: {
      Id: id,
      WithBannerads: WithBannerads,
      WithVideoads: WithVideoads,
      WithRemotertbzones: WithRemotertbzones,
      WithRemotevideozones: WithRemotevideozones,
      WithNativeads: WithNativeads,
    },
  },
  ).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update campaign data
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateCampaign(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    Name: {
      type: 'string',
      required: false,
    },
    ClicksDailyLimit: {
      type: 'int',
      required: false,
      map: 'clicks',
    },
    ClicksLimitPerIp: {
      type: 'int',
      required: false,
      map: 'limit',
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/update', { Campaign: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change campaign status
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeCampaignStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Campaign: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
